<script>
// import { FacebookIcon, TwitterIcon, InstagramIcon } from "vue-feather-icons";
export default {
  // components: { FacebookIcon, TwitterIcon, InstagramIcon },
  data() {
    return {
      teamData: [
        {
          profile: require("@/assets/images/team/Rev_Dr_Leroy_Perry.webp"),
          name: "Reverend Dr. Leroy Perry",
          designation: "Yale Cultural Ambassador; Pastor, St. Stephens AME Zion Church",
        },
        {
          profile: require("@/assets/images/team/Reverend_Elvin_Clayton.webp"),
          name: "Reverend Elvin Clayton",
          designation: "Yale Cultural Ambassador; Pastor, Walters Memorial AME Zion Church  ",
        },
        {
          profile: require("@/assets/images/team/Tesheia_Johnson.webp"),
          name: "Tesheia Johnson, MBA, MHS",
          designation: "Chief Operation Officer; Yale Center for Clinical Investigation",
        },
        {
          profile: require("@/assets/images/team/Allen_Hsiao.webp"),
          name: "Allen Hsiao, MD, FACP",
          designation: "Chief Health Information Officer; Yale New Haven Health System; Yale School of Medicine",
        },
      ],
    };
  },
};
</script>

<template>
  <!-- Team start -->
  <section class="section bg-light" id="team">
    <div class="container">
      <div class="row justify-content-center mb-4">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">Your Team </h2>
          <p class="text-muted">
           <!-- text placeholder -->
          </p>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-3 col-sm-6"
          v-for="(item, index) of teamData"
          :key="index"
        >
          <div
            class="team-box mt-4 position-relative overflow-hidden rounded text-center shadow"
          >
            <div class="position-relative overflow-hidden">
              <img
                :src="item.profile"
                alt=""
                class="img-fluid d-block mx-auto"
              />
            </div>
            <div class="p-4">
              <h5 class="font-size-19 mb-1">{{ item.name }}</h5>
              <p class="text-muted font-size-14 mb-0">
                {{ item.designation }}
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Team end -->
</template>
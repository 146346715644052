<script>
import { BoxIcon, LayersIcon, ServerIcon } from "vue-feather-icons";

export default {
  components: { BoxIcon, LayersIcon, ServerIcon },
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      this.$router.push(id);
    },
    URL(url) {
      window.location.href = url;
    },
    newTab (url) {
      window.open(url, '_blank');
    },
  }
  };
</script>

<template>
  <!-- Services start -->
  <section class="section" id="services">
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">Available Services</h2>
          <p class="text-muted">
            Virtual health services are available for all patients. We offer a wide range of services to meet your needs. We are here to help you.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
         <a @click="URL(`services`)" >
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <box-icon class=""></box-icon>
              </div>
              <h4 class="mb-3 font-size-22">Telemedicine</h4>
              <p class="text-muted mb-0">
                Telemedicine is a virtual health service that allows you to connect with your doctor and your patient in real time.
              </p>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div
            class="service-box text-center px-4 py-5 position-relative mb-4"
          >
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <layers-icon class=""></layers-icon>
              </div>
              <h4 class="mb-3 font-size-22">Health Check</h4>
              <p class="text-muted mb-0">
                Health check is a virtual health service that allows you to check your health status.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <server-icon class=""></server-icon>
              </div>
              <h4 class="mb-3 font-size-22">Food Services</h4>
              <p class="text-muted mb-0">
                Food services are a virtual service that allows you to find local resources to support your needs.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
     <!-- second section -->
     <div class="container">
       <div class="row">
        <div class="col-lg-4">
         <a @click="newTab(`https://ynhh.org`)" >
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <box-icon class=""></box-icon>
              </div>
              <h4 class="mb-3 font-size-22">Community Services</h4>
              <p class="text-muted mb-0">
                Learn about community services and how to get involved.
              </p>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4" >
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <layers-icon class=""></layers-icon>
              </div>
              <h4 class="mb-3 font-size-22">Clinical Trials</h4>
              <p class="text-muted mb-0">
                Learn about clinical trials and how to participate.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <server-icon class=""></server-icon>
              </div>
              <h4 class="mb-3 font-size-22">Resources Directory</h4>
              <p class="text-muted mb-0">
                Other helpful resources for you and your family.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
     <!-- end of second section  -->
  </section>
  <!-- Services end -->
</template>
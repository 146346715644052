<script>
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  components: { ArrowRightIcon },
  data() {
    return {};
  },
  methods: {
  URL(url) {
      window.location.href = url;
    },
    newTab (url) {
      window.open(url, '_blank');
    },
  }
  };
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Clinical Trials at Yale</h2>
            <p class="text-muted">
              Clinical trials help researchers develop new and better treatments. The information they provide is the only way for medical breakthroughs to reach the public.
              </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">Why Volunteer?</h2>
            <p class="text-muted mb-5">
              There is no substitute for the people who participate in clinical research. The information provided by volunteers can't be found under a microscope, in a test tube or even by using the most technologically advanced scientific instruments. Volunteers like you are the only way for medical breakthroughs to reach the public.
            </p>
            <a href="https://medicine.yale.edu/ycci/clinicaltrials/learnmore/why/" target="_blank" class="btn btn-primary"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                  src="@/assets/images/CT_volunteer.webp"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-left"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section pb-0">
          <div class="col-md-6">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img
                  src="@/assets/images/minority_volunteers.webp"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-right"
              />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto">
            <h2 class="mb-4">
              Underserved Minority Populations
            </h2>
            <p class="text-muted mb-5">
             Human beings are very much alike. Only one-tenth of one percent of our genes varies from one person to the next. But even though we are very similar, illnesses – and the medications that treat them – affect each of us differently, depending on our gender, age, health, ethnicity and race.<br>

It’s important to test new treatments in different groups of people in order to understand what treatments work best for them. The best way to know that a particular medicine is right for someone is to test it in similar people.
            </p>
            <a href="https://medicine.yale.edu/ycci/clinicaltrials/categories/underserved-minority-populations/" target="_blank" class="btn btn-primary"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->

    <section class="section bg-gradient-primary">
      <div
        class="bg-overlay-img"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/demos.png') + ')',
        }"
      ></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center">
              <h1 class="text-white mb-4">Yale Tradition of Discovery</h1>
              <p class="text-white mb-5 font-size-16">
                The Yale School of Medicine has a tradition of developing promising new treatments such as the insulin pump to treat diabetes, two drugs to treat HIV infection and the first treatment that arms the body's cells to create a cancer-fighting immune response. All of these discoveries involve years of painstaking research and scientific collaboration. But they also depend on the help of people like you.
              </p>
              <a href="#" class="btn btn-lg btn-light">Learn More</a>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Cta end -->
  </div>
</template>